// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advinhe-privacy-html-js": () => import("./../src/pages/advinhe-privacy.html.js" /* webpackChunkName: "component---src-pages-advinhe-privacy-html-js" */),
  "component---src-pages-chill-hop-privacy-html-js": () => import("./../src/pages/chill-hop-privacy.html.js" /* webpackChunkName: "component---src-pages-chill-hop-privacy-html-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

